<template>
  <div id="resetPage">
    <el-row class="titleBar">
      <el-col :span="24" align="center">
        <div class="logo-bg">
          
        </div>
      </el-col>
    </el-row>
    <div class="container">
      <div id="resetForm">
        <el-form ref="ruleForm" :model="ruleForm" :rules="rules">
          <el-form-item label="" prop="mail1">
            <el-input
              :placeholder="$t('forgetPWD.mail1')"
              v-model="ruleForm.mail1"
              clearable
            >
            </el-input>
          </el-form-item>
         
          <el-form-item style="text-align:center;">
            <el-button
              type="primary"
              @click="submitRuleForm('ruleForm')"
              style="width:100%"
              >{{$t('forgetPWD.reset')}}</el-button
            >
             <div class="forgetPassword"><router-link :to="ROUTER_PATH.LOGIN" class="el-link el-link--default ">{{$t('forgetPWD.back')}}</router-link></div>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <CommonFooter />
  </div>
</template>

<script>
  import CommonFooter from "@/components/common/CommonFooter.vue";
  import { forgetPassword } from "@/api/account";
  import { ROUTER_PATH } from "@/constants/common.js";
  export default {
    name: "ForgetPassword",
    components: { CommonFooter },
    data: () => {
      return {
        ROUTER_PATH: ROUTER_PATH,
        ruleForm: {
          mail1: ""
        },
        rules: {
          mail1: [
            { required: true, message: "请输入联系人邮件地址", trigger: "blur" }
          ]
        }
      };
    },
    mounted() {
      
    },
    methods: {
      submitRuleForm(ruleFormName) {
        this.$refs[ruleFormName].validate(valid => {
          if (valid) {
            forgetPassword({
              ...this.ruleForm
            }).then(response => {
              if(response){
                this.$message({
                  type: "success",
                  message: "密码重置成功，请查收邮件。"
                });
    
                this.$router.push(ROUTER_PATH.LOGIN);
              }
            });
          } else {
            return false;
          }
        });
      }
    },
    computed: {
      
    }
  };
  </script>
  
  <style>
  #resetPage {
    display: flex;
    height: 100%;
    background-color: #f9f9f9;
    flex-direction: column;
  }
  #resetPage .container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  #resetPage .titleBar {
    background-color: #fff;
  }

  #resetForm {
    box-sizing: border-box;
    width: 400px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.09);
  }
  #resetForm img {
    align-self: center;
    max-width: 100%;
    height: auto;
  }
  #resetForm .forgetPassword{
    margin:0px 0px 10px 0px;
    text-align: right;
  }
  .logo-bg {
    background-image: url(../../assets/images/logo_m.png);
    background-repeat: no-repeat;
    background-size: 140px 45px;
    width: 80%;
    height: 45px;
    margin-top: 35px;
    text-align: right;
  }
  </style>